import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { ActivityInfo } from "./ActivityInfo";
import UpcomingEvents from "./UpcomingEvents";
import { DocumentIcon, ArrowRightCircleIcon } from "@heroicons/react/24/solid";

export function ActivitiesWidget() {
  return (
    <section className="w-full">
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="w-full max-w-full border !border-blue-gray-100 p-2"
      >
        <CardHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          floated={false}
          shadow={false}
          className="rounded-none"
        >
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="text-3xl"
          >
            Needs your attention!
          </Typography>
        </CardHeader>
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="px-4"
        >
          <ActivityInfo
            icon={DocumentIcon}
            text="document forms overdue"
            value={3}
            primaryColor="#FFF3C4"
            secondaryColor="#FFECB3"
          />
          <ActivityInfo
            icon={DocumentIcon}
            text="medication forms overdue"
            value={2}
            primaryColor="#EADCF8"
            secondaryColor="#E1D7F6"
          />
          <ActivityInfo
            icon={ArrowRightCircleIcon}
            text="discharges overdue"
            value={13}
            primaryColor="#D4E8FF"
            secondaryColor="#C9E2FF"
          />

          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="text-lg mt-6"
          >
            Upcoming Events 🎉
          </Typography>
          <UpcomingEvents />
        </CardBody>
      </Card>
    </section>
  );
}

export default ActivitiesWidget;
