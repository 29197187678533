import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
  Typography,
  DialogFooter,
  Input,
} from "@material-tailwind/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { format } from "date-fns";
import { urlServer } from "../../../Configuration/heroku";

interface Event {
  _id?: string;
  title: string;
  start: string;
  end: string | null;
  eventType: number;
}

interface EventModalProps {
  open: boolean;
  onClose: () => void;
  date: string | null;
  event: Event | null;
  onSave: (
    title: string,
    start: string | null,
    end: string | null,
    eventType: number
  ) => void;
  onDelete: () => void;
}

const EventModal: React.FC<EventModalProps> = ({
  open,
  onClose,
  date,
  event,
  onSave,
  onDelete,
}) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [form, setForm] = useState({
    title: event?.title || "",
    startDate: event ? new Date(event.start) : new Date(),
    endDate: event?.end ? new Date(event.end) : null,
    facilityId: user.facilities[0].facilityId,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (event) {
      setForm({
        title: event.title,
        startDate: new Date(event.start),
        endDate: event.end ? new Date(event.end) : null,
        facilityId: user.facilities[0].facilityId,
      });
    } else {
      setForm({
        title: "",
        startDate: date ? new Date(date) : new Date(),
        endDate: null,
        facilityId: user.facilities[0].facilityId,
      });
    }
  }, [event, date, user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleStartDateChange = (date: Date | null) => {
    setForm((prevForm) => ({
      ...prevForm,
      startDate: date || new Date(),
    }));
  };

  const handleEndDateChange = (date: Date | null) => {
    setForm((prevForm) => ({
      ...prevForm,
      endDate: date,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (event) {
        await axios.put(
          `${urlServer}/api/${user.facilities[0].facilityId}/event/${event._id}`,
          {
            title: form.title,
            startDate: form.startDate ? form.startDate.toISOString() : null,
            endDate: form.endDate ? form.endDate.toISOString() : null,
          }
        );
      } else {
        await axios.post(
          `${urlServer}/api/${user.facilities[0].facilityId}/event`,
          {
            title: form.title,
            startDate: form.startDate ? form.startDate.toISOString() : null,
            endDate: form.endDate ? form.endDate.toISOString() : null,
          }
        );
      }
      setLoading(false);
      onSave(
        form.title,
        form.startDate.toISOString(),
        form.endDate?.toISOString() || null,
        event?.eventType || 1
      );
      onClose();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Dialog
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      open={open}
      handler={onClose}
      className="p-4"
      size="xs"
    >
      <DialogHeader
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="justify-between pb-0"
      >
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="blue-gray"
          className="mb-1 font-bold"
        >
          {event ? "Edit Event" : "Create Event"}
        </Typography>
        <IconButton
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="gray"
          size="sm"
          variant="text"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="pt-0"
      >
        <div className="flex items-center flex-col gap-x-2 gap-y-4 mt-6">
          <div className="flex flex-col w-full">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Event Title
            </Typography>
            <Input
              crossOrigin={undefined}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="gray"
              className="w-full"
              name="title"
              value={form.title}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-col w-full">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Select Start Date & Time
            </Typography>
            <DatePicker
              selected={form.startDate}
              onChange={handleStartDateChange}
              showTimeSelect
              dateFormat="yyyy-MM-dd HH:mm"
              className="w-full h-10"
              customInput={
                <Input
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  crossOrigin={undefined}
                  color="gray"
                  className="w-full"
                  name="startDate"
                  value={
                    form.startDate
                      ? format(form.startDate, "yyyy-MM-dd HH:mm")
                      : ""
                  }
                  readOnly
                />
              }
            />
          </div>

          <div className="flex flex-col w-full">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Select End Date & Time
            </Typography>
            <DatePicker
              selected={form.endDate}
              onChange={handleEndDateChange}
              showTimeSelect
              dateFormat="yyyy-MM-dd HH:mm"
              className="w-full h-10"
              customInput={
                <Input
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  crossOrigin={undefined}
                  color="gray"
                  className="w-full"
                  name="endDate"
                  value={
                    form.endDate ? format(form.endDate, "yyyy-MM-dd HH:mm") : ""
                  }
                  readOnly
                />
              }
            />
          </div>
        </div>
      </DialogBody>
      <DialogFooter
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="gap-2"
      >
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        {event && (
          <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="outlined"
            color="red"
            onClick={handleDelete}
          >
            Delete
          </Button>
        )}
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          onClick={() => handleSave()}
        >
          Save changes
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default EventModal;
