import React, { FunctionComponent, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CaregiversCard } from "./CaregiversCard";
import { UnitCard } from "./UnitCard";
import { RoomCard } from "./RoomCard";
import { urlServer } from "../Configuration/heroku";
import { IUnit } from "../Data/unit";

interface Caregiver {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  relation: string;
}

export const UnitProfile: FunctionComponent = () => {
  const { id } = useParams();
  const [unit, setUnit] = useState<IUnit | null>(null);
  const [caregivers, setCaregivers] = useState<Caregiver[]>([]);

  const fetchUnit = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/unit/${id}`, {
        params: {
          caregivers: true,
          rooms: true,
        },
      });
      const data = response.data;
      setUnit(data);
      setCaregivers(data.caregivers || []);
    } catch (error) {
      console.error("Error fetching unit data:", error);
    }
  };

  useEffect(() => {
    fetchUnit();
  }, [id]);
  const handleSaveCaregiver = (
    field: keyof Caregiver,
    newValue: string,
    index: number
  ) => {
    setCaregivers((prevCaregivers) => {
      const updatedCaregivers = [...prevCaregivers];
      updatedCaregivers[index] = {
        ...updatedCaregivers[index],
        [field]: newValue,
      };
      return updatedCaregivers;
    });
  };

  const handleSave = (field: keyof IUnit, newValue: string) => {
    if (unit) {
      setUnit((prevUnit) =>
        prevUnit ? { ...prevUnit, [field]: newValue } : null
      );
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
      {unit && <UnitCard unit={unit} handleSave={handleSave} />}
      <CaregiversCard
        caregivers={caregivers}
        handleSave={handleSaveCaregiver}
        fetchUnit={fetchUnit}
      />
      {unit && (
        <RoomCard
          rooms={unit.rooms}
          unit={unit}
          fetchUnit={() => fetchUnit()}
        />
      )}
    </div>
  );
};
