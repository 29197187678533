import React, { useState } from "react";
import {
  Card,
  CardBody,
  Typography,
  Input,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Textarea,
} from "@material-tailwind/react";
import { UserIcon, EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import moment from "moment";
import { urlServer } from "../Configuration/heroku";
import { IUnit } from "../Data/unit";

export const UnitCard: React.FC<{
  unit: IUnit;
  handleSave: (field: keyof IUnit, newValue: string) => void;
}> = ({ unit, handleSave }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const toggleEditing = () => {
    setIsEditing((prevState) => !prevState);
  };

  const handleSaveClick = async () => {
    toggleEditing();
    try {
      await axios.put(`${urlServer}/api/unit/${unit._id}`, {
        firstName: unit.firstName,
        lastName: unit.lastName,
        dateOfBirth: unit.dateOfBirth,
        address: unit.address,
        city: unit.city,
        state: unit.state,
        zipCode: unit.zipCode,
        notes: unit.notes,
      });
    } catch (error) {
      console.error("Error updating unit:", error);
    }
  };
  return (
    <Card
      className="lg:col-span-1"
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      <Typography
        variant="h6"
        className="mx-5 my-3 flex gap-1"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <UserIcon className="!w-5 !h-5 " /> Client Information
      </Typography>
      <CardBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="flex items-center gap-3">
              <Typography
                color="blue-gray"
                className="!font-semibold"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                Unit Details
              </Typography>
            </div>
            <div className="flex items-center gap-3">
              <Menu placement="left">
                <MenuHandler>
                  <IconButton
                    variant="text"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <EllipsisHorizontalIcon className="h-8 w-8" />
                  </IconButton>
                </MenuHandler>
                <MenuList
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  {isEditing ? (
                    <MenuItem
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      onClick={handleSaveClick}
                    >
                      Save Unit
                    </MenuItem>
                  ) : (
                    <MenuItem
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      onClick={toggleEditing}
                    >
                      Edit Unit
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </div>
          </div>

          <div>
            <label className="text-gray-700 font-semibold">First Name</label>
            <Input
              value={unit.firstName}
              onChange={(e) => handleSave("firstName", e.target.value)}
              className="border p-1 w-full"
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              disabled={!isEditing}
            />
          </div>

          <div>
            <label className="text-gray-700 font-semibold">Last Name</label>
            <Input
              value={unit.lastName}
              onChange={(e) => handleSave("lastName", e.target.value)}
              className="border p-1 w-full"
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              disabled={!isEditing}
            />
          </div>

          <div>
            <label className="text-gray-700 font-semibold">Date of Birth</label>
            <Input
              type="date"
              value={moment(unit.dateOfBirth).format("YYYY-MM-DD")}
              onChange={(e) => handleSave("dateOfBirth", e.target.value)}
              className="border p-1 w-full"
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              disabled={!isEditing}
            />
          </div>

          <div>
            <label className="text-gray-700 font-semibold">Address</label>
            <Input
              value={unit.address}
              onChange={(e) => handleSave("address", e.target.value)}
              className="border p-1 w-full"
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              disabled={!isEditing}
            />
          </div>

          <div>
            <label className="text-gray-700 font-semibold">City</label>
            <Input
              value={unit.city}
              onChange={(e) => handleSave("city", e.target.value)}
              className="border p-1 w-full"
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              disabled={!isEditing}
            />
          </div>

          <div>
            <label className="text-gray-700 font-semibold">State</label>
            <Input
              value={unit.state}
              onChange={(e) => handleSave("state", e.target.value)}
              className="border p-1 w-full"
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              disabled={!isEditing}
            />
          </div>

          <div>
            <label className="text-gray-700 font-semibold">Zip Code</label>
            <Input
              value={unit.zipCode}
              onChange={(e) => handleSave("zipCode", e.target.value)}
              className="border p-1 w-full"
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              disabled={!isEditing}
            />
          </div>
          <div>
            <label className="text-gray-700 font-semibold">Notes</label>
            <Textarea
              value={unit.notes}
              onChange={(e: any) => handleSave("notes", e.target.value)}
              className="border p-1 w-full"
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              disabled={!isEditing}
            />
          </div>

          {/* add multi selector */}
          {/* <div> <MultiSelect
              label="Select Allergies"
              options={options}
              selectedOptions={selectedOptions}
              onChange={handleMultiSelectChange}
              editable={isEditing}
            /> </div> */}
        </div>
      </CardBody>
    </Card>
  );
};
