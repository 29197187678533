import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentCard } from "../../../Components/ContentCard";
import SearchBar from "../../../Components/Search";
import Units from "../../../Dialogs/Units";
import Pagination from "../../../Components/Pagination";
import { format } from "date-fns";
import axios from "axios";
import { urlServer } from "../../../Configuration/heroku";

const TABLE_HEAD = ["Name", "Date of Birth", "Rooms", "Enrollment"];

export const UnitsPage = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const elementsPerPageOptions = [2, 25, 50, 100];
  const [totalPages, setTotalPages] = useState(10);

  const [view, setView] = useState("table");
  const [units, setUnits] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);

  const handleSearch = (units: any[]) => {
    setFilteredData(units);
  };
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const facilityId = user.facilities[0].facilityId;
  const getUnitsByFacility = async (page: number, rows: number) => {
    try {
      const response = await axios.get(`${urlServer}/api/units/${facilityId}`, {
        params: { page, rowsPerPage: rows },
      });
      const data = response.data;
      setUnits(data.units);
      setFilteredData(data.units);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching units:", error);
    }
  };
  useEffect(() => {
    getUnitsByFacility(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  return (
    <div className="p-4">
      <Card
        className="h-full w-full"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <CardHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          floated={false}
          shadow={false}
          className={` rounded-none overflow-visible ${
            view !== "table" ? "pb-40 md:pb-16 lg:pb-8" : ""
          }`}
        >
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="h5"
                color="blue-gray"
              >
                Clients
              </Typography>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                className="mt-1 font-normal"
              >
                See information about all clients
              </Typography>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              {/* <Button
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                onClick={() => {
                  view === "table" ? setView("cards") : setView("table");
                }}
                variant="outlined"
                size="sm"
              >
                view {view === "table" ? "cards" : "table"}
              </Button> */}
              <Button
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className="flex items-center gap-3"
                size="sm"
                onClick={() => setIsSelectDialogOpen(true)}
              >
                <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Add Client
              </Button>
            </div>
          </div>
          <div className="float-end">
            <div className="w-full md:w-72">
              <SearchBar
                placeholder="Search client..."
                data={units}
                dataKeys={["firstName", "lastName", "room", "teacher"]}
                onSearch={handleSearch}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="overflow-auto px-0"
        >
          {view === "table" ? (
            <table className="mt-4 w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head, index) => (
                    <th
                      key={head}
                      className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                    >
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="small"
                        color="blue-gray"
                        className="flex items-center justify-between gap-2 leading-none opacity-70 !font-bold text-gray-900"
                      >
                        {head}
                        {index !== TABLE_HEAD.length - 1 && (
                          <ChevronUpDownIcon
                            strokeWidth={2}
                            className="h-4 w-4"
                          />
                        )}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map(
                  (
                    {
                      _id,
                      firstName,
                      lastName,
                      dateOfBirth,
                      rooms,

                      createdAt,
                    },
                    index
                  ) => {
                    const isLast = index === filteredData.length - 1;
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";

                    return (
                      <tr
                        key={_id}
                        className="hover:bg-gray-100 cursor-pointer hover:shadow-md"
                        onClick={() =>
                          navigate(`/facility/${facilityId}/unit/${_id}`, {
                            state: { units },
                          })
                        }
                      >
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography
                                placeholder={undefined}
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                                className="font-normal"
                              >
                                {firstName} {lastName}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {format(new Date(dateOfBirth), "MM/dd/yyyy")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {rooms.map((room: any) => room.name).join(", ")}
                          </Typography>
                        </td>
                        {/* <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {teacher}
                          </Typography>
                        </td> */}
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {isNaN(new Date(createdAt).getTime())
                              ? "Invalid date"
                              : format(new Date(createdAt), "MMMM dd, yyyy")}
                          </Typography>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          ) : (
            <div className="grid w-full grid-cols-1 gap-x-10 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-5">
              {filteredData.map(
                (
                  {
                    attendanceStatus,
                    firstName,
                    lastName,
                    _id,
                    dateOfBirth,
                    room,
                    caregivers,
                  },
                  index
                ) => (
                  <ContentCard
                    key={index + _id}
                    id={_id}
                    img={
                      "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg"
                    }
                    firstName={firstName}
                    lastName={lastName}
                  />
                )
              )}
            </div>
          )}
        </CardBody>
        <CardFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex items-center justify-between border-t border-blue-gray-50 p-4"
        >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRowsPerPage}
            elementsPerPageOptions={elementsPerPageOptions}
          />
        </CardFooter>
      </Card>
      <Units
        open={isSelectDialogOpen}
        onClose={() => {
          setIsSelectDialogOpen(false);
          getUnitsByFacility(currentPage, rowsPerPage);
        }}
      />
    </div>
  );
};
