import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
  Typography,
  DialogFooter,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
} from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { urlServer } from "../Configuration/heroku";
import { IUser } from "../Data/user";

interface SelectDialogProps {
  open: boolean;
  onClose: () => void;
  users: IUser[];
}

export const AssignUsers: FunctionComponent<SelectDialogProps> = (props) => {
  const { id } = useParams();
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Set<string>>(new Set());
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${urlServer}/api/${user.facilities[0].facilityId}/users`
      );
      let data = response.data;

      data = data
        .filter(
          (user: IUser) =>
            !props.users.some((user2: IUser) => user2._id === user._id)
        )
        .filter((currentUser: IUser) => {
          const facility = currentUser.facilities.find(
            (facility) => facility.facilityId === user.facilities[0].facilityId
          );
          if (facility) {
            return facility.role !== "caregiver";
          }
          return true;
        });

      setUsers(data);
    } catch (error) {
      console.error("Error fetching room data:", error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, [props.users]);

  const handleCheckboxChange = (userId: string) => {
    setSelectedUsers((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(userId)) {
        newSelected.delete(userId);
      } else {
        newSelected.add(userId);
      }
      return newSelected;
    });
  };

  const handleSubmit = async () => {
    try {
      const usersArray = Array.from(selectedUsers);

      for (const userId of usersArray) {
        await axios.put(`${urlServer}/api/user/${userId}/rooms`, {
          roomIds: [id],
        });
      }

      setSelectedUsers(new Set());
      setUsers(users);
      fetchUsers();
      props.onClose();
    } catch (error) {
      console.error("Error assigning units:", error);
    }
  };

  return (
    <>
      {users.length === 0 ? (
        <>
          {" "}
          <Dialog
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="p-4"
            open={props.open}
            handler={props.onClose}
          >
            <DialogHeader
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="justify-between pb-0"
            >
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="blue-gray"
                className="mb-1 font-bold"
              >
                No Users Available
              </Typography>
              <IconButton
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                size="sm"
                variant="text"
                onClick={props.onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </DialogHeader>
          </Dialog>
        </>
      ) : (
        <>
          <Dialog
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="p-4"
            open={props.open}
            handler={props.onClose}
          >
            <DialogHeader
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="justify-between pb-0"
            >
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="blue-gray"
                className="mb-1 font-bold"
              >
                Assign User
              </Typography>
              <IconButton
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                size="sm"
                variant="text"
                onClick={props.onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  className="h-4 w-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </IconButton>
            </DialogHeader>
            <DialogBody
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="overflow-y-scroll pt-0"
            >
              {users.map((room: IUser, index: number) => (
                <div className="flex flex-col gap-4 items-center" key={index}>
                  <Card
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    className="mt-6 w-full h-40"
                  >
                    <CardBody
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    >
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="h6"
                        color="blue-gray"
                        className="mb-2"
                      >
                        {room.firstName} ({room.lastName})
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {room.email}
                      </Typography>
                    </CardBody>
                    <CardFooter
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      className="pt-0"
                    >
                      <Checkbox
                        crossOrigin={undefined}
                        id={room._id}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        label="Select Room"
                        checked={selectedUsers.has(room._id)}
                        onChange={() => handleCheckboxChange(room._id)}
                      />
                    </CardFooter>
                  </Card>
                </div>
              ))}
            </DialogBody>
            <DialogFooter
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="gap-2"
            >
              <Button
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </DialogFooter>
          </Dialog>
        </>
      )}
    </>
  );
};

export default AssignUsers;
