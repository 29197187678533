import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Avatar,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../Components/Search";
import { RoomsCard } from "./RoomsCard";
import { AddRoom } from "../../../Dialogs/AddRoom";
import axios from "axios";
import { urlServer } from "../../../Configuration/heroku";

const DATA = [
  {
    id: "1",
    img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg",
    present: 42,
    absent: 32,
    numberOfStudents: 40,
    students: [
      {
        id: "S001",
        name: "Alice Johnson",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg",
      },
      {
        id: "S002",
        name: "Bob Smith",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "S003",
        name: "Carol White",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
    ],
    staff: [
      {
        id: "T001",
        name: "Emma Brown",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "T002",
        name: "Liam Davis",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg",
      },
    ],
    name: "John Michael",
    room: "Room 1",
    attendanceStatus: "present",
  },
  {
    id: "2",
    img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
    present: 22,
    absent: 9,
    numberOfStudents: 60,
    students: [
      {
        id: "S004",
        name: "David Martin",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "S005",
        name: "Ella Scott",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "S006",
        name: "Grace Lee",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
    ],
    staff: [
      {
        id: "T003",
        name: "Sophia Wilson",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "T004",
        name: "James Taylor",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
    ],
    name: "Alexa Liras",
    room: "Room 2",
    attendanceStatus: "present",
  },
  {
    id: "3",
    img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-1.jpg",
    present: 22,
    absent: 10,
    numberOfStudents: 60,
    students: [
      {
        id: "S007",
        name: "Henry Miller",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "S008",
        name: "Olivia Martinez",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "S009",
        name: "Lucas Hernandez",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
    ],
    staff: [
      {
        id: "T005",
        name: "Mia Lopez",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "T006",
        name: "Benjamin Clark",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
    ],
    name: "Laurent Perrier",
    room: "Room 3",
    attendanceStatus: "absent",
  },

  {
    id: "4123213",
    img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-4.jpg",
    present: 22,
    absent: 10,
    numberOfStudents: 60,
    students: [
      {
        id: "S010",
        name: "Charlotte Thompson",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "S011",
        name: "Evelyn King",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "S012",
        name: "Jack Walker",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
    ],
    staff: [
      {
        id: "T007",
        name: "Harper Young",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "T008",
        name: "William Hall",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
    ],
    name: "Michael Levi",
    room: "Room 4",
    attendanceStatus: "vacation",
  },
  {
    id: "12124",
    img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-4.jpg",
    present: 22,
    absent: 10,
    numberOfStudents: 60,
    students: [
      {
        id: "S010",
        name: "Charlotte Thompson",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "S011",
        name: "Evelyn King",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "S012",
        name: "Jack Walker",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
    ],
    staff: [
      {
        id: "T007",
        name: "Harper Young",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
      {
        id: "T008",
        name: "William Hall",
        img: "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
      },
    ],
    name: "Michael Levi",
    room: "Room 4",
    attendanceStatus: "vacation",
  },
];

const TABLE_HEAD = ["Room", "Clients", "Staff", "Room Number"];

export const RoomsPage = () => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const elementsPerPageOptions = [10, 25, 50, 100];
  const [totalPages, setTotalPages] = useState(10);
  const [view, setView] = useState("table");
  const [tableRows, setTableRows] = useState(DATA);
  const [openPopover, setOpenPopover] = useState<string | null>(null);
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const triggers = (id: string) => ({
    onClick: () => setOpenPopover(id),
    onMouseEnter: () => {
      if (openPopover !== null) {
        setOpenPopover(id);
      }
    },
    onMouseLeave: () => setOpenPopover(null),
  });
  const facilityId = user.facilities[0].facilityId;
  const getRoomsByFacility = async (page: number, rows: number) => {
    try {
      const response = await axios.get(
        `${urlServer}/api/rooms/${user.facilities[0].facilityId}`,
        {
          params: { page, rowsPerPage: rows },
        }
      );
      const data = response.data;
      setRooms(data.room);
      setFilteredData(data.room);
      setTotalPages(data.totalPages);
      if (response.status === 200) {
        setTotalPages(data.totalPages);
      } else {
        console.error("Failed to fetch rooms");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSearch = (units: any[]) => {
    setFilteredData(units);
  };
  const next = () => {
    if (currentPage === 10) return;
    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    getRoomsByFacility(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);
  return (
    <div className="p-4">
      <Card
        className="h-full w-full"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <CardHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          floated={false}
          shadow={false}
          className={` rounded-none overflow-visible ${
            view !== "table" ? "pb-40 md:pb-16 lg:pb-8" : ""
          }`}
        >
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="h5"
                color="blue-gray"
              >
                Rooms list
              </Typography>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                className="mt-1 font-normal"
              >
                See information about all rooms
              </Typography>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              {/* <Button
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                onClick={() => {
                  view === "table" ? setView("cards") : setView("table");
                }}
                variant="outlined"
                size="sm"
              >
                view {view === "table" ? "cards" : "table"}
              </Button> */}
              <Button
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className="flex items-center gap-3"
                size="sm"
                onClick={() => setIsSelectDialogOpen((cur) => !cur)}
              >
                <AcademicCapIcon strokeWidth={2} className="h-4 w-4" /> Add Room
              </Button>
            </div>
          </div>
          <div className="float-end">
            <div className="w-full md:w-72">
              <SearchBar
                placeholder="Search clients..."
                data={rooms}
                dataKeys={["name", "capacity", "roomNumber"]}
                onSearch={handleSearch}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="overflow-auto px-0"
        >
          {view === "table" ? (
            <table className="mt-4 w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head, index) => (
                    <th
                      key={head}
                      className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                    >
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        variant="small"
                        color="blue-gray"
                        className="flex items-center justify-between gap-2 leading-none opacity-70 !font-bold text-gray-900"
                      >
                        {head}{" "}
                        {index !== TABLE_HEAD.length - 1 && (
                          <ChevronUpDownIcon
                            strokeWidth={2}
                            className="h-4 w-4"
                          />
                        )}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map(
                  (
                    { _id, name, units, users, capacity, roomNumber },
                    index
                  ) => {
                    const isLast = index === tableRows.length - 1;
                    const classes = isLast
                      ? "px-3 py-1"
                      : "p-3 border-b border-blue-gray-50";
                    return (
                      <tr
                        key={_id}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() =>
                          navigate(`/facility/${facilityId}/room/${_id}`)
                        }
                      >
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {capacity ? (
                              <>
                                {" "}
                                {units.length} / {capacity}
                              </>
                            ) : (
                              <> {units.length} </>
                            )}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {users.length}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            className="font-normal"
                          >
                            {roomNumber}
                          </Typography>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          ) : (
            <div className="grid w-full grid-cols-1 gap-x-10 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xxl:grid-cols-5">
              {tableRows.map(
                (
                  { id, staff, room, present, absent, img, numberOfStudents },
                  index
                ) => (
                  <RoomsCard
                    key={index + id}
                    id={id}
                    img={img}
                    staff={staff}
                    room={room}
                    present={present}
                    absent={absent}
                    students={[]}
                    numberOfStudents={numberOfStudents}
                  />
                )
              )}
            </div>
          )}
        </CardBody>
        <CardFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex items-center justify-between border-t border-blue-gray-50 p-4"
        >
          <section className="container mx-auto py-0 px-4">
            <div className="flex items-end gap-y-8 justify-between">
              <div className="flex flex-col md:flex-row md:items-center gap-3">
                <Typography
                  variant="small"
                  className="text-gray-600 font-bold"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Results: <span className="text-gray-900">40</span>
                </Typography>
                <div className="flex flex-col md:flex-row md:items-center gap-2">
                  <Typography
                    variant="small"
                    className="text-gray-600 font-bold"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    {view === "table" ? "Rows" : "Cards"} per page:
                  </Typography>
                  <Menu>
                    <MenuHandler>
                      <Button
                        variant="outlined"
                        className="flex items-center gap-2 !border-gray-300"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        {rowsPerPage}
                        <ChevronDownIcon strokeWidth={3} className="w-3 h-3" />
                      </Button>
                    </MenuHandler>
                    <MenuList
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    >
                      {elementsPerPageOptions
                        .filter((option) => option !== rowsPerPage)
                        .map((option) => (
                          <MenuItem
                            key={option}
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            onClick={() => setRowsPerPage(option)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Menu>
                </div>
              </div>
              <div className="flex gap-4 items-center">
                <IconButton
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  variant="outlined"
                  onClick={prev}
                  disabled={currentPage === 1}
                  className="border-gray-300"
                >
                  <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
                </IconButton>
                <Typography
                  variant="small"
                  className="font-bold text-gray-600"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Page <strong className="text-gray-900">{currentPage}</strong>{" "}
                  of <strong className="text-gray-900">{totalPages}</strong>
                </Typography>
                <IconButton
                  variant="outlined"
                  onClick={next}
                  disabled={currentPage === totalPages}
                  className="border-gray-300"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
                </IconButton>
              </div>
            </div>
          </section>
        </CardFooter>
      </Card>
      <AddRoom
        open={isSelectDialogOpen}
        onClose={() => {
          setIsSelectDialogOpen(false);
          getRoomsByFacility(currentPage, rowsPerPage);
        }}
        user={user}
      />
    </div>
  );
};
