import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD2HjqJYkQ4mBbtbS88DBjByMTfl8XV238",
  authDomain: "myvitalpath-c4f97.firebaseapp.com",
  projectId: "myvitalpath-c4f97",
  storageBucket: "myvitalpath-c4f97.firebasestorage.app",
  messagingSenderId: "931482923520",
  appId: "1:931482923520:web:3e2630a0ba189eaba45ee5",
  measurementId: "G-XGZBXCHSLF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
