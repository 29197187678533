import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Typography,
  Input,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Textarea,
  Button,
} from "@material-tailwind/react";
import {
  EllipsisHorizontalIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";
import { useParams } from "react-router-dom";
import { AssignRoom } from "../Dialogs/AssignRoom";
import { urlServer } from "../Configuration/heroku";
import { IUnit } from "../Data/unit";

interface Room {
  _id: string;
  name: string;
  roomNumber: string;
  description: string;
}

export const RoomCard: React.FC<{
  unit: IUnit;
  rooms: Room[];
  fetchUnit: () => void;
}> = ({ unit, rooms, fetchUnit }) => {
  const { id } = useParams();

  const [isEditing, setIsEditing] = useState<boolean[]>([]);
  const [updatedRooms, setUpdatedRooms] = useState<Room[]>(rooms);
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);

  useEffect(() => {
    setUpdatedRooms(rooms);
    setIsEditing(Array(rooms.length).fill(false));
  }, [rooms]);

  const toggleEditing = (index: number) => {
    setIsEditing((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleInputChange = (
    index: number,
    field: keyof Room,
    value: string
  ) => {
    const newRooms = [...updatedRooms];
    newRooms[index] = { ...newRooms[index], [field]: value };
    setUpdatedRooms(newRooms);
  };

  const handleSaveClick = async (index: number) => {
    const room = updatedRooms[index];
    try {
      await axios.put(`${urlServer}/api/room/${room._id}`, room);
      fetchUnit();
    } catch (error) {
      console.error("Error updating room:", error);
    }
    toggleEditing(index);
  };

  const handleDeleteRoom = async (index: number) => {
    const room = updatedRooms[index];
    try {
      await axios.put(`${urlServer}/api/unit/${id}/room/${room._id}/remove`);
      const newRooms = updatedRooms.filter((_, i) => i !== index);
      setUpdatedRooms(newRooms);
      fetchUnit();
    } catch (error) {
      console.error("Error deleting room:", error);
    }
  };
  return (
    <>
      {rooms.length === 0 ? (
        <Card
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="lg:col-span-1"
        >
          <div className="flex justify-between mx-2">
            <div className="flex items-center gap-3">
              <Typography
                variant="h6"
                className="mx-5 my-3 flex gap-1"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <AcademicCapIcon className="!w-5 !h-5 " />
                Room Information{" "}
              </Typography>
            </div>
          </div>

          <CardBody
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="flex justify-center items-center mt-8"
          >
            <Button
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              onClick={() => setIsSelectDialogOpen(true)}
            >
              Assign Room
            </Button>
          </CardBody>
        </Card>
      ) : (
        <>
          {" "}
          <Card
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="lg:col-span-1"
          >
            <div className="flex justify-between mx-2">
              <div className="flex items-center gap-3">
                <Typography
                  variant="h6"
                  className="mx-5 my-3 flex gap-1"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <AcademicCapIcon className="!w-5 !h-5 " />
                  Room Information{" "}
                </Typography>
              </div>
              <div className="flex items-center gap-3">
                <Menu placement="left">
                  <MenuHandler>
                    <IconButton
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      variant="text"
                    >
                      <EllipsisHorizontalIcon className="h-8 w-8" />
                    </IconButton>
                  </MenuHandler>
                  <MenuList
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <MenuItem
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      onClick={() => setIsSelectDialogOpen(true)}
                    >
                      Assign Room
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>

            <CardBody
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              {" "}
              {updatedRooms.map((room, index) => (
                <div className="flex flex-col gap-4" key={room._id}>
                  <div className="flex justify-between">
                    <div className="flex items-center gap-3">
                      <Typography
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        color="blue-gray"
                        className="!font-semibold"
                      >
                        Room Details
                      </Typography>
                    </div>
                    <div className="flex items-center gap-3">
                      <Menu placement="left">
                        <MenuHandler>
                          <IconButton
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                            variant="text"
                          >
                            <EllipsisHorizontalIcon className="h-8 w-8" />
                          </IconButton>
                        </MenuHandler>
                        <MenuList
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                        >
                          {isEditing[index] ? (
                            <MenuItem
                              placeholder={undefined}
                              onPointerEnterCapture={undefined}
                              onPointerLeaveCapture={undefined}
                              onClick={() => handleSaveClick(index)}
                            >
                              Save Room
                            </MenuItem>
                          ) : (
                            <>
                              {" "}
                              <MenuItem
                                placeholder={undefined}
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                                onClick={() => handleDeleteRoom(index)}
                              >
                                Remove Room
                              </MenuItem>{" "}
                            </>
                          )}
                        </MenuList>
                      </Menu>
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-700 font-semibold">
                      Room Name
                    </label>
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      value={room.name}
                      className="border p-1 w-full"
                      disabled={!isEditing[index]}
                      onChange={(e) =>
                        handleInputChange(index, "name", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label className="text-gray-700 font-semibold">
                      Room Number
                    </label>
                    <Input
                      crossOrigin={undefined}
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      value={room.roomNumber}
                      className="border p-1 w-full"
                      disabled={!isEditing[index]}
                      onChange={(e) =>
                        handleInputChange(index, "roomNumber", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label className="text-gray-700 font-semibold">Notes</label>
                    <Textarea
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      value={room.description}
                      className="border p-1 w-full"
                      disabled={!isEditing[index]}
                      onChange={(e) =>
                        handleInputChange(index, "description", e.target.value)
                      }
                    />
                  </div>
                </div>
              ))}
            </CardBody>
          </Card>
        </>
      )}
      <AssignRoom
        open={isSelectDialogOpen}
        onClose={() => {
          setIsSelectDialogOpen(false);
          fetchUnit();
        }}
        rooms={rooms}
      />
    </>
  );
};
