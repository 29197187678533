import {
  Card,
  Carousel,
  CardBody,
  CardFooter,
  Typography,
  Avatar,
  MenuItem,
  MenuList,
  Menu,
  IconButton,
  MenuHandler,
} from "@material-tailwind/react";
import {
  BookOpenIcon,
  MicrophoneIcon,
  ExclamationCircleIcon,
  DocumentIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
import axios from "axios";
import { useState, lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { urlServer } from "../Configuration/heroku";

const AnnouncementDialog = lazy(() => import("../Dialogs/Announcement"));

type Attachment = {
  uploadBy: string;
  uploadDate: string;
  file: string;
};

type FeedCardProps = {
  id: Number;
  createdAt: string;
  createdBy: string;
  title: string;
  attachment?: Attachment[];
  description: string;
  type: string;
  user: any;
  getAnnouncements?: () => void;
};

export function FeedCard({
  id,
  createdAt,
  createdBy,
  title,
  attachment = [],
  description,
  type,
  user,
  getAnnouncements,
}: FeedCardProps) {
  const location = useLocation();
  const isInRoomsPath = location.pathname.includes("/room");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<FeedCardProps | null>(null);
  const openEditDialog = () => {
    setSelectedAnnouncement({
      id,
      createdAt,
      createdBy,
      title,
      attachment,
      description,
      type,
      user,
    });
    setIsSelectDialogOpen(true);
  };
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const previewText =
    description.length > 350
      ? description.substring(0, 350) + "..."
      : description;

  const imageAttachments = attachment.filter((att) =>
    /\.(jpg|jpeg|png|gif)$/i.test(att.file)
  );
  const documentAttachments = attachment.filter((att) =>
    /\.(pdf|doc|docx)$/i.test(att.file)
  );

  const handleDelete = async (id: any) => {
    try {
      await axios.delete(`${urlServer}/api/announcement/${id}`);
      getAnnouncements && getAnnouncements();
    } catch (error) {
      console.error("Error deleting announcement:", error);
    }
  };

  return (
    <>
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="my-4"
      >
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="float-end">
            {user?.facilities[0]?.role === "staff" && isInRoomsPath && (
              <Menu placement="left">
                <MenuHandler
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <IconButton
                    variant="text"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <EllipsisHorizontalIcon className="h-8 w-8" />
                  </IconButton>
                </MenuHandler>
                <MenuList
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <MenuItem
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    onClick={openEditDialog}
                  >
                    Edit Announcement
                  </MenuItem>
                  <MenuItem
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    onClick={() => handleDelete(id)}
                  >
                    Delete Announcement
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </div>
          <Typography
            variant="h4"
            color="blue-gray"
            className="mt-1 mb-2"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {type === "Homework" ? (
              <span className="flex items-center gap-2 text-orange-400">
                <BookOpenIcon className="!w-5 !h-5" />
                {type}
              </span>
            ) : type === "Announcement" ? (
              <span className="flex items-center gap-2">
                <MicrophoneIcon className="!w-5 !h-5" />
                {type}
              </span>
            ) : type === "Reminder" ? (
              <span className="flex items-center gap-2">
                <ExclamationCircleIcon className="!w-5 !h-5" />
                {type}
              </span>
            ) : (
              <span>{type}</span>
            )}
          </Typography>
          <Typography
            variant="h5"
            className="mt-1 mb-2"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {title}
          </Typography>
          <Typography
            color="gray"
            className="font-normal"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {isExpanded ? description : previewText}
            {description.length > 350 && (
              <span
                className="text-blue-500 cursor-pointer"
                onClick={toggleExpansion}
              >
                {isExpanded ? " Show less" : " Read more"}
              </span>
            )}
          </Typography>{" "}
          {imageAttachments.length > 0 && (
            <div className="my-4">
              <Carousel
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                {imageAttachments.map((att, index) => (
                  <img
                    key={index}
                    src={att.file}
                    alt={`Attachment ${index + 1}`}
                    className="w-full h-auto object-cover"
                  />
                ))}
              </Carousel>
            </div>
          )}
          {documentAttachments.length > 0 && (
            <div className="mt-4">
              {documentAttachments.map((att, index) => (
                <div key={index} className="mt-2">
                  <Typography
                    variant="h6"
                    className="mt-1"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    Attachments:
                  </Typography>
                  <span className="flex items-center gap-1">
                    <DocumentIcon className="!w-4 !h-4" />
                    <a
                      href={att.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500"
                    >
                      {att.file}
                    </a>
                  </span>
                </div>
              ))}
            </div>
          )}
        </CardBody>
        <CardFooter
          className="flex items-center pt-1"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <Avatar
            src={`https://www.material-tailwind.com/img/avatar6.jpg`}
            alt="avatar"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <div className="ml-4">
            <Typography
              className="font-medium"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              {createdBy}
            </Typography>
            <Typography
              variant="small"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              {moment(createdAt).fromNow()}
            </Typography>
          </div>
        </CardFooter>
      </Card>
      <Suspense fallback={""}>
        {isSelectDialogOpen && (
          <AnnouncementDialog
            open={isSelectDialogOpen}
            onClose={() => {
              setIsSelectDialogOpen(false);
              getAnnouncements && getAnnouncements();
            }}
            announcements={selectedAnnouncement}
          />
        )}
      </Suspense>
    </>
  );
}

export default FeedCard;
