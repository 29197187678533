import {
  Breadcrumbs,
  Card,
  CardBody,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  IconButton,
  Typography,
  Tabs,
  Tab,
  TabsHeader,
  TabsBody,
  TabPanel,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import {
  Square3Stack3DIcon,
  UserIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import { useParams, useNavigate } from "react-router-dom";
import { AnnouncementFeed } from "../../../Components/AnnouncementFeed";
import { UnitsTable } from "../../../Tables/UnitsTable";
import { UsersTable } from "../../../Tables/UsersTable";
import axios from "axios";
import RoomOverview from "../../../Components/Room/RoomOverview";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { urlServer } from "../../../Configuration/heroku";

export const RoomPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("feed");
  const [announcements, setAnnouncements] = useState<any[]>([]);
  interface Room {
    name: string;
    units: any[];
    users: any[];
    roomNumber: string;
  }
  const [room, setRoom] = useState<Room>({
    units: [],
    users: [],
    name: "",
    roomNumber: "",
  });
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const getAnnouncements = async () => {
    try {
      const response = await axios.get(
        `${urlServer}/api/${user.facilities[0].facilityId}/announcement/${id}`
      );
      setAnnouncements(response.data);
    } catch (error: any) {
      console.error(
        "Error fetching announcements:",
        error.response?.data || error.description
      );
    }
  };
  const getRoom = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/room/${id}`);
      setRoom(response.data);
    } catch (error: any) {
      console.error(
        "Error fetching units:",
        error.response?.data || error.description
      );
    }
  };
  const handleDeleteRoom = async () => {
    try {
      await axios.delete(`${urlServer}/api/room/${id}`);
      navigate(`/facility/${user.facilities[0].facilityId}/rooms`);
    } catch (error: any) {
      console.error(
        "Error deleting room:",
        error.response?.data || error.description
      );
    }
  };

  useEffect(() => {
    getAnnouncements();
    getRoom();
  }, []);
  const data = [
    {
      label: "Feed",
      value: "feed",
      icon: Square3Stack3DIcon,
      desc: `It really matters and then like it really doesn't matter.
          What matters is the people who are sparked by it. And the people
          who are like offended by it, it doesn't matter.`,
      Component: AnnouncementFeed,
      props: { announcements, user, getAnnouncements },
    },
    {
      label: "Clients",
      value: "clients",
      icon: UserIcon,
      desc: `We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams.`,
      Component: UnitsTable,
      props: { units: room.units || [], refresh: () => getRoom() },
    },
    {
      label: "Users",
      value: "users",
      icon: UserIcon,
      desc: `We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams.`,
      Component: UsersTable,
      props: {
        units: room.units || [],
        user,
        users: room.users,
        refresh: () => getRoom(),
      },
    },
    {
      label: "Overview",
      value: "overview",
      icon: DocumentTextIcon,
      desc: `Because it's about motivating the doers. Because I'm here
          to follow my dreams and inspire other people to follow their dreams, too.`,
      Component: RoomOverview,
      props: { room },
    },
  ];
  return (
    <div className="flex flex-col gap-4 p-4">
      <Breadcrumbs
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <a
          href={`/facility/${user.facilities[0].facilityId}/rooms`}
          className="opacity-60"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
        </a>
        <a
          href={`/facility/${user.facilities[0].facilityId}/rooms`}
          className="opacity-60"
        >
          Rooms
        </a>
        <a>{room.name}</a>
      </Breadcrumbs>
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="flex items-center justify-between w-full">
            <div className="ml-4">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="h6"
              >
                {room.name}
              </Typography>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="blue-gray"
              >
                Room Number: {room.roomNumber}
              </Typography>
            </div>
            <div className="float-end">
              <Menu placement="left">
                <MenuHandler>
                  <IconButton
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    variant="text"
                  >
                    <EllipsisHorizontalIcon className="h-8 w-8" />
                  </IconButton>
                </MenuHandler>
                <MenuList
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <MenuItem
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    onClick={handleDeleteRoom}
                  >
                    Delete Room
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </CardBody>
      </Card>
      <Tabs
        value={selectedTab}
        onChange={(value: string) => setSelectedTab(value)}
      >
        <TabsHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {data.map(({ label, value, icon }) => (
            <Tab
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              key={value}
              value={value}
            >
              <div className="flex items-center gap-2">
                {React.createElement(icon, { className: "w-5 h-5" })}
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          {data.map(({ value, Component, props }) => (
            <TabPanel key={value} value={value} className="px-0">
              {Component ? (
                <Component
                  {...props}
                  user={user}
                  users={room.users || []}
                  room={room}
                  units={room.units || []}
                  refresh={() => getRoom()}
                  getAnnouncements={getAnnouncements}
                  announcements={announcements}
                />
              ) : null}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
};
