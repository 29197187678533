import {
  AcademicCapIcon,
  BuildingOfficeIcon,
  CalendarDaysIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { ComponentType } from "react";

export type Role = "admin" | "staff" | "caregiver" | "teacher";

export interface IRoute {
  name: string;
  icon: ComponentType<React.SVGProps<SVGSVGElement>>;
  path: string;
}

export const RolePermissions: Record<Role, string[]> = {
  admin: [
    "units",
    "rooms",
    "users",
    "invoices",
    "calendar",
    "chat",
    "facilities",
  ],
  staff: ["units", "rooms", "users", "invoices", "calendar", "chat"],
  caregiver: ["calendar", "chat"],
  teacher: ["units", "rooms", "users", "invoices", "calendar", "chat"],
};

export const Routes: IRoute[] = [
  {
    name: "units",
    icon: AcademicCapIcon,
    path: "/facility/:facilityId/units",
  },
  {
    name: "rooms",
    icon: ClipboardDocumentListIcon,
    path: "/facility/:facilityId/rooms",
  },
  {
    name: "users",
    icon: UserCircleIcon,
    path: "/facility/:facilityId/users",
  },
  {
    name: "invoices",
    icon: DocumentTextIcon,
    path: "/facility/:facilityId/invoices",
  },
  {
    name: "calendar",
    icon: CalendarDaysIcon,
    path: "/facility/:facilityId/calendar",
  },
  {
    name: "chat",
    icon: ChatBubbleLeftRightIcon,
    path: "/facility/:facilityId/chat",
  },
  // {
  //   name: "facilities",
  //   icon: BuildingOfficeIcon,
  //   path: "/facilities",
  // },
];
