import React, { useState } from "react";
import {
  Card,
  CardBody,
  Typography,
  Select,
  Option,
} from "@material-tailwind/react";
import { FunctionComponent } from "react";
import { MicrophoneIcon, PlusIcon } from "@heroicons/react/24/solid";
import { FeedCard } from "./FeedCard";
import { Announcement } from "../Dialogs/Announcement";
import { useLocation } from "react-router-dom";

export const AnnouncementFeed: FunctionComponent<{
  announcements: any[];
  getAnnouncements: () => Promise<void>;
}> = ({ announcements, getAnnouncements }) => {
  const location = useLocation();
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<string>("");

  const filteredAnnouncements = selectedType
    ? announcements?.filter(
        (announcement) => announcement.type === selectedType
      ) ?? []
    : announcements ?? [];
  const isInRoomsPath = location.pathname.includes("/room");
  return (
    <section className="w-full flex justify-center items-start">
      <Card
        className="w-full max-w-3xl"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="flex justify-between items-center mx-4">
          <Typography
            variant="h6"
            className="m-3 flex items-center gap-1"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <MicrophoneIcon className="!w-5 !h-5" /> Announcements
          </Typography>
          {user?.facilities[0]?.role === "staff" && isInRoomsPath && (
            <PlusIcon
              className="w-6 h-6 text-black-500 cursor-pointer font-bold"
              onClick={() => setIsSelectDialogOpen((cur) => !cur)}
            />
          )}
        </div>

        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="flex justify-start md:justify-center">
            <Select
              label="Announcement Type"
              className="w-full"
              value={selectedType}
              onChange={(value) => setSelectedType(value as string)}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Option value="">Select...</Option>
              <Option value="Homework">Homework</Option>
              <Option value="Reminder">Reminder</Option>
              <Option value="Announcement">Announcement</Option>
            </Select>
          </div>

          {filteredAnnouncements.length > 0 ? (
            filteredAnnouncements.map((announcement) => (
              <FeedCard
                user={user}
                key={announcement._id}
                id={announcement._id}
                createdAt={announcement.createdAt}
                createdBy={
                  announcement.createdBy.firstName +
                  " " +
                  announcement.createdBy.lastName
                }
                title={announcement.title}
                attachment={announcement.attachment}
                description={announcement.description}
                type={announcement.type}
                getAnnouncements={getAnnouncements}
              />
            ))
          ) : (
            <div className="flex justify-center items-center h-64">
              <Typography
                variant="h6"
                className="text-center"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                No announcements found.
              </Typography>
            </div>
          )}
        </CardBody>
      </Card>
      <Announcement
        open={isSelectDialogOpen}
        onClose={() => {
          setIsSelectDialogOpen(false);
          getAnnouncements && getAnnouncements();
        }}
      />
    </section>
  );
};
