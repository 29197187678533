import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
  Typography,
  DialogFooter,
  Input,
} from "@material-tailwind/react";
import { FunctionComponent, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { urlServer } from "../Configuration/heroku";

interface SelectDialogProps {
  open: boolean;
  onClose: () => void;
}

const Caregivers: FunctionComponent<SelectDialogProps> = (props) => {
  const { id } = useParams();
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    relation: "",
    email: "",
    facilityId: user.facilities[0].facilityId,
    units: [id],
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClear = () => {
    setFormData({
      firstName: "",
      lastName: "",
      relation: "",
      email: "",
      facilityId: user.facilities[0].facilityId,
      units: [id],
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${urlServer}/api/units/${id}/caregivers`,
        formData
      );
      if (response.status === 201) {
        handleClear();
        props.onClose();
      } else {
        console.error("Failed to add unit");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Dialog
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="p-4"
      open={props.open}
      handler={props.onClose}
    >
      <DialogHeader
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="justify-between pb-0"
      >
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="blue-gray"
          className="mb-1 font-bold"
        >
          Assign Caregiver
        </Typography>
        <IconButton
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="gray"
          size="sm"
          variant="text"
          onClick={props.onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="overflow-y-scroll pt-0"
      >
        <div className="flex items-center flex-col md:flex-row gap-4 mt-6">
          <div className="w-full">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              First Name
            </Typography>
            <Input
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="gray"
              placeholder="First Name"
              labelProps={{
                className: "hidden",
              }}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Last Name
            </Typography>
            <Input
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="gray"
              placeholder="Last Name"
              labelProps={{
                className: "hidden",
              }}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="w-full">
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium"
          >
            Relation
          </Typography>
          <Input
            crossOrigin={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="gray"
            placeholder="Relation"
            labelProps={{
              className: "hidden",
            }}
            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
            name="relation"
            value={formData.relation}
            onChange={handleChange}
          />
        </div>
        <div className="w-full">
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium"
          >
            Email
          </Typography>
          <Input
            crossOrigin={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="gray"
            placeholder="Email"
            labelProps={{
              className: "hidden",
            }}
            className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
      </DialogBody>
      <DialogFooter
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="gap-2"
      >
        <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default Caregivers;
