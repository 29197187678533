import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  IconButton,
  Typography,
  DialogFooter,
  Input,
  Select,
  Option,
  Textarea,
} from "@material-tailwind/react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import { FunctionComponent, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { urlServer } from "../Configuration/heroku";

interface SelectDialogProps {
  open: boolean;
  onClose: () => void;
  announcements?: any;
}

export const Announcement: FunctionComponent<SelectDialogProps> = ({
  open,
  onClose,
  announcements,
}) => {
  const { id } = useParams();

  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [announcement, setAnnouncement] = useState({
    title: "",
    description: "",
    type: "",
    rooms: [],
    createdBy: user,
  });

  const handleInputChange = (
    e:
      | React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      | string
  ) => {
    if (typeof e === "string") {
      setAnnouncement((prev) => ({
        ...prev,
        type: e,
      }));
    } else {
      const { name, value } = e.target;
      setAnnouncement((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    if (announcements) {
      axios.put(`${urlServer}/api/announcement/${announcements.id}`, {
        title: announcement.title,
        description: announcement.description,
        type: announcement.type,
      });
      onClose();
    } else {
      try {
        await axios.post(
          `${urlServer}/api/${user.facilities[0].facilityId}/announcement/${id}`,
          {
            title: announcement.title,
            description: announcement.description,
            type: announcement.type,
            createdBy: announcement.createdBy,
          }
        );
        onClose();
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (announcements) {
      setAnnouncement(announcements);
    }
  }, [announcements]);
  return (
    <>
      <Dialog
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="p-4"
        open={open}
        handler={onClose}
      >
        <DialogHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="justify-between pb-0"
        >
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="blue-gray"
            className="mb-1 font-bold"
          >
            {announcements ? "Edit Announcement" : "Create An Announcement"}
          </Typography>
          <IconButton
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            color="gray"
            size="sm"
            variant="text"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </DialogHeader>
        <DialogBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="overflow-y-scroll pt-0"
        >
          <div className="flex items-center flex-col md:flex-row gap-4 mt-6">
            <div className="w-full">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                color="blue-gray"
                className="mb-1 font-medium"
              >
                Title
              </Typography>
              <Input
                crossOrigin={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="gray"
                placeholder="Title"
                labelProps={{
                  className: "hidden",
                }}
                className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
                name="title"
                value={announcement.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-full">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                color="blue-gray"
                className="mb-1 font-medium"
              >
                Type
              </Typography>
              <Select
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                labelProps={{
                  className: "hidden",
                }}
                className="border-t-blue-gray-200 aria-[expanded=true]:border-t-primary"
                onChange={(value) => handleInputChange(value as string)}
                value={announcement.type}
              >
                <Option value="Homework">Homework</Option>
                <Option value="Announcement">Announcement</Option>
                <Option value="Reminder">Reminder</Option>
              </Select>
            </div>
          </div>
          <div>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              color="blue-gray"
              className="mb-1 font-medium"
            >
              Description
            </Typography>
            <Textarea
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              rows={6}
              placeholder="Description"
              labelProps={{
                className: "hidden",
              }}
              className="w-full placeholder:opacity-100 focus:border-t-primary border-t-blue-gray-200"
              name="description"
              value={announcement.description}
              onChange={handleInputChange}
            />
          </div>
          <label
            htmlFor="upload"
            className="grid place-items-center py-10 rounded-lg border border-dashed border-gray-300 mt-6"
          >
            <input type="file" id="upload" className="hidden" />
            <IconButton
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="text"
              className="mb-4"
            >
              <ArrowUpTrayIcon
                className="h-8 w-8 text-gray-900"
                strokeWidth={2}
              />
            </IconButton>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              color="blue-gray"
              className="mb-1 font-bold"
            >
              Drag and Drop or{" "}
              <a href="#" className="underline">
                Choose a Local File
              </a>
            </Typography>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="small"
              className="font-normal text-gray-600"
            >
              Supported formats: .png, .jpg, .svg
            </Typography>
          </label>
        </DialogBody>
        <DialogFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="gap-2"
        >
          <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Announcement;
