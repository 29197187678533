import React from "react";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import {
  BanknotesIcon,
  UserPlusIcon,
  UserGroupIcon,
  HomeIcon,
} from "@heroicons/react/24/solid";

interface DataPoint {
  icon: React.ReactNode;
  title: string;
  value: string;
  description: string;
  color: string;
}

const dataPoints: DataPoint[] = [
  {
    icon: <BanknotesIcon className="!w-5 !h-5 " />,
    title: "Clients",
    value: "42",
    description: "+55% ",
    color: "!text-green-500",
  },
  {
    icon: <UserGroupIcon className="!w-5 !h-5 " />,
    title: "Staff",
    value: "4",
    description: "+3% ",
    color: "!text-green-500",
  },
  {
    icon: <UserPlusIcon className="!w-5 !h-5 " />,
    title: "Absentees",
    value: "13",
    description: "-2% ",
    color: "!text-red-500",
  },
  {
    icon: <BanknotesIcon className="!w-5 !h-5" />,
    title: "Forms",
    value: "12",
    description: "-25% ",
    color: "!text-green-500",
  },
  {
    icon: <BanknotesIcon className="!w-5 !h-5 " />,
    title: "Forms",
    value: "12",
    description: "-25% ",
    color: "!text-green-500",
  },
];

const OverviewCard: React.FC = () => {
  return (
    <Card
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="w-full border !border-blue-gray-100 p-2"
    >
      <Typography
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        variant="h6"
        className="m-3 flex gap-1"
      >
        <HomeIcon className="!w-5 !h-5 " /> Site Overview
      </Typography>
      <CardBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="py-2 grid grid-cols-1 md:grid-cols-5 gap-1"
      >
        {dataPoints.map((data, index) => (
          <div
            key={index}
            className="flex flex-col items-center space-y-1 text-center p-2 "
          >
            {data.icon}

            <Typography
              variant="small"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="text-sm font-semibold"
            >
              {data.title}
            </Typography>
            <Typography
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="h6"
              className={`${data.color}`}
            >
              {data.value}
            </Typography>
            <Typography
              variant="small"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="text-sm font-semibold"
            >
              {data.description}
            </Typography>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default OverviewCard;
