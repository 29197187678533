import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { Square3Stack3DIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import RoomsTable from "./RoomsTable";
import { UnitsTable } from "./UnitsTable";
export default function TabsWidget() {
  const data = [
    {
      label: "Rooms",
      value: "dashboard",
      icon: Square3Stack3DIcon,
      role: "teacher",
      //   if teacher role you see an overview of class if admin you see table for all rooms
      // desc: role === "teacher" ? <RoomsTable /> : <UnitsTable />,
      desc: <RoomsTable />,
    },
    {
      label: "Clients",
      value: "profile",
      icon: UserCircleIcon,
      desc: <UnitsTable units={[]} user={undefined} />,
    },
  ];
  return (
    <Card
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      className="w-full max-w-full border !border-blue-gray-100 p-2 mt-4"
    >
      <CardHeader
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        floated={false}
        shadow={false}
        className="rounded-none"
      >
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="text-3xl"
        >
          Overview
        </Typography>
      </CardHeader>
      <CardBody
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="px-4"
      >
        <Tabs
          value="dashboard"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <TabsHeader
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {data.map(({ label, value, icon }) => (
              <Tab
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                key={value}
                value={value}
              >
                <div className="flex items-center gap-2">
                  {React.createElement(icon, { className: "w-5 h-5" })}
                  {label}
                </div>
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {data.map(({ value, desc }) => (
              <TabPanel key={value} value={value}>
                {desc}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </CardBody>
    </Card>
  );
}
