import React, { useState } from "react";
import {
  Card,
  CardBody,
  Typography,
  Input,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Button,
} from "@material-tailwind/react";
import { UserIcon, EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import Caregivers from "../Dialogs/Caregivers";
import { urlServer } from "../Configuration/heroku";

interface Caregiver {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  relation: string;
}

export const CaregiversCard: React.FC<{
  caregivers: Caregiver[];
  handleSave: (field: keyof Caregiver, newValue: string, index: number) => void;
  fetchUnit: () => void;
}> = ({ caregivers, handleSave, fetchUnit }) => {
  const [isEditing, setIsEditing] = useState<boolean[]>(
    Array(caregivers.length).fill(false)
  );
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);

  const toggleEditing = (index: number) => {
    setIsEditing((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleDelete = async (index: number) => {
    toggleEditing(index);
    const caregiver = caregivers[index];
    try {
      await axios.delete(`${urlServer}/api/caregiver/${caregiver._id}`);
      fetchUnit();
    } catch (error) {
      console.error("Error updating caregiver:", error);
    }
  };

  const handleSaveClick = async (index: number) => {
    toggleEditing(index);
    const caregiver = caregivers[index];
    try {
      await axios.put(`${urlServer}/api/caregiver/${caregiver._id}`, {
        firstName: caregiver.firstName,
        lastName: caregiver.lastName,
        email: caregiver.email,
        relation: caregiver.relation,
      });
    } catch (error) {
      console.error("Error updating caregiver:", error);
    }
  };

  return (
    <>
      {caregivers.length === 0 ? (
        <>
          {" "}
          <Card
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="lg:col-span-1"
          >
            <div className="flex justify-between mx-2">
              <div className="flex items-center gap-3">
                <Typography
                  variant="h6"
                  className="mx-5 my-3 flex gap-1"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <UserIcon className="!w-5 !h-5 " />
                  Caregiver Information{" "}
                </Typography>
              </div>
            </div>

            <CardBody
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className="flex justify-center items-center mt-8"
            >
              <Button
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                onClick={() => setIsSelectDialogOpen(true)}
              >
                Assign Caregiver
              </Button>
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          {" "}
          <Card
            className="lg:col-span-1"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div className="flex justify-between mx-2">
              <div className="flex items-center gap-3">
                <Typography
                  variant="h6"
                  className="mx-5 my-3 flex gap-1"
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <UserIcon className="!w-5 !h-5 " />
                  Caregiver Information
                </Typography>
              </div>
              <div className="flex items-center gap-3">
                <Menu placement="left">
                  <MenuHandler>
                    <IconButton
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      variant="text"
                    >
                      <EllipsisHorizontalIcon className="h-8 w-8" />
                    </IconButton>
                  </MenuHandler>
                  <MenuList
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <MenuItem
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      onClick={() => setIsSelectDialogOpen(true)}
                    >
                      Assign Caregiver
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>
            <CardBody
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className="flex flex-col gap-4">
                {caregivers.map((caregiver, index) => (
                  <div key={index} className="flex flex-col gap-4">
                    <div className="flex justify-between">
                      <div className="flex items-center gap-3">
                        <Typography
                          color="blue-gray"
                          className="!font-semibold"
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                        >
                          Caregiver {index + 1}
                        </Typography>
                      </div>
                      <div className="flex items-center gap-3">
                        <Menu placement="left">
                          <MenuHandler>
                            <IconButton
                              variant="text"
                              placeholder={undefined}
                              onPointerEnterCapture={undefined}
                              onPointerLeaveCapture={undefined}
                            >
                              <EllipsisHorizontalIcon className="h-8 w-8" />
                            </IconButton>
                          </MenuHandler>
                          <MenuList
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                          >
                            {isEditing[index] ? (
                              <MenuItem
                                placeholder={undefined}
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                                onClick={() => handleSaveClick(index)}
                              >
                                Save Caregiver
                              </MenuItem>
                            ) : (
                              <MenuItem
                                placeholder={undefined}
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                                onClick={() => toggleEditing(index)}
                              >
                                Edit Caregiver
                              </MenuItem>
                            )}
                            <MenuItem
                              placeholder={undefined}
                              onPointerEnterCapture={undefined}
                              onPointerLeaveCapture={undefined}
                              onClick={() => handleDelete(index)}
                            >
                              Remove Caregiver
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </div>
                    </div>

                    <div>
                      <label className="text-gray-700 font-semibold">
                        First Name
                      </label>
                      <Input
                        value={caregiver.firstName}
                        onChange={(e) =>
                          handleSave("firstName", e.target.value, index)
                        }
                        className="border p-1 w-full"
                        crossOrigin={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        disabled={!isEditing[index]}
                      />
                    </div>

                    <div>
                      <label className="text-gray-700 font-semibold">
                        Last Name
                      </label>
                      <Input
                        value={caregiver.lastName}
                        onChange={(e) =>
                          handleSave("lastName", e.target.value, index)
                        }
                        className="border p-1 w-full"
                        crossOrigin={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        disabled={!isEditing[index]}
                      />
                    </div>

                    <div>
                      <label className="text-gray-700 font-semibold">
                        Relation
                      </label>
                      <Input
                        value={caregiver.relation}
                        onChange={(e) =>
                          handleSave("relation", e.target.value, index)
                        }
                        className="border p-1 w-full"
                        crossOrigin={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        disabled={!isEditing[index]}
                      />
                    </div>

                    <div>
                      <label className="text-gray-700 font-semibold">
                        Email
                      </label>
                      <Input
                        type="email"
                        value={caregiver.email}
                        onChange={(e) =>
                          handleSave("email", e.target.value, index)
                        }
                        className="border p-1 w-full"
                        crossOrigin={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                        disabled={!isEditing[index]}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </>
      )}
      <Caregivers
        open={isSelectDialogOpen}
        onClose={() => {
          setIsSelectDialogOpen(false);
          fetchUnit();
        }}
      />
    </>
  );
};
