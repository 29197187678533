import { useState } from "react";
import { Dialog, Input, Typography } from "@material-tailwind/react";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { validateEmail } from "../Helpers/common";
import axios from "axios";
import { urlServer } from "../Configuration/heroku";

export const AddUserDialog = ({ isOpen, onClose }: any) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lasttName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(true);

  const sendInvitation = async () => {
    if (validateEmail(email)) {
      setIsEmailValid(true);
      const auth = getAuth();
      const facilityData = localStorage.getItem("facility");
      if (facilityData) {
        const facilityId = JSON.parse(facilityData)._id;
        const actionCodeSettings = {
          url: `${urlServer}/sign-up?email=${email}&facilityId=${facilityId}`,
          handleCodeInApp: true, // This will open the link in the app
        };

        if (firstName && lasttName && email && role && auth && auth.currentUser)
          try {
            // Create invitation in MongoDB
            await axios.post(
              `${urlServer}/api/${facilityId}/create-invitation`,
              {
                firstName: firstName,
                lastName: lasttName,
                email: email,
                role: role,
                createdBy: auth.currentUser?.email,
              }
            );
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            onClose();
          } catch (e) {
            console.error(e);
          }
      }
    } else {
      setIsEmailValid(false);
    }
  };

  return (
    <Dialog
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      open={isOpen}
      handler={onClose}
    >
      <div className="p-4">
        <h2 className="mb-4 text-lg">Add a new user</h2>
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className="mb-4 p-2 border border-gray-300 rounded w-full"
          placeholder="First Name"
        />
        <input
          type="text"
          value={lasttName}
          onChange={(e) => setLastName(e.target.value)}
          className="mb-4 p-2 border border-gray-300 rounded w-full"
          placeholder="Last Name"
        />
        <input
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setIsEmailValid(true);
          }}
          className={`${
            isEmailValid ? "mb-4" : ""
          } p-2 border border-gray-300 rounded w-full`}
          placeholder="Email"
        />
        {!isEmailValid && (
          <p className="mb-4 mt-1 text-red-600">Email is not valid!</p>
        )}
        <input
          type="text"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className="mb-4 p-2 border border-gray-300 rounded w-full"
          placeholder="Role"
        />
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="mr-2 p-2 bg-gray-500 text-white rounded"
          >
            Cancel
          </button>
          <button
            onClick={sendInvitation}
            className="p-2 bg-blue-500 text-white rounded"
          >
            Add
          </button>
        </div>
      </div>
    </Dialog>
  );
};
