import { auth } from "../Configuration/firebase";
import { useEffect, useState } from "react";
import { ProfileMenu } from "./ProfileMenu";
import { PowerIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { IFacility } from "../Data/facility";
import { Typography } from "@material-tailwind/react";
import { IUser } from "../Data/user";
import { useNavigate } from "react-router-dom";
export const Navbar = () => {
  const navigate = useNavigate();
  const [user1, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const facilityId = user1 ? user1.facilities[0].facilityId : null;

  const [facility, setFacility] = useState<IFacility | null>(null);

  const signOut = () => {
    localStorage.clear();
    auth.signOut();
    setUser(null);
  };

  const profileMenuItems = [
    {
      label: "My Profile",
      icon: UserCircleIcon,
      onClick: () => {
        navigate(`/facility/${facilityId}/user/${user1._id}`, {
          state: { user1 },
        });
      },
    },
    {
      label: "Sign Out",
      icon: PowerIcon,
      onClick: () => signOut(),
    },
  ];

  useEffect(() => {
    const userData = localStorage.getItem("user");
    const facilityData = localStorage.getItem("facility");
    if (userData && facilityData) {
      const user = JSON.parse(userData);
      const facility = JSON.parse(facilityData);
      setUser(user);
      setFacility(facility);
    }
  }, []);

  return (
    <div className="flex justify-between items-center p-4">
      <div className="flex items-center">
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="h5"
        >
          {facility?.name || "MyVitalPath"}
        </Typography>
      </div>
      <div className="flex items-center">
        {user1 ? (
          <ProfileMenu
            profileMenuItems={profileMenuItems}
            name={user1 ? `${user1.firstName} ${user1.lastName}` : ""}
          />
        ) : (
          <button onClick={() => navigate("/login")}>Log In</button>
        )}
      </div>
    </div>
  );
};
