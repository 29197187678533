import OverViewWidget from "./OverViewWidget";
import ActivitiesWidget from "./ActivitiesWidget";
import TabsWidget from "./TabsWidget";
import Greeting from "./Greeting";
import { Button } from "@material-tailwind/react";
import { AddUserDialog } from "../../../Dialogs/AddUser";
import { useState } from "react";

function DashboardPage() {
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);

  return (
    <section className="px-8 lg:px-16 py-10">
      <Greeting name={"Stark"} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        <div className="md:col-span-2">
          <OverViewWidget />
          <TabsWidget />
        </div>
        <div className="md:col-span-1">
          <ActivitiesWidget />
        </div>
        {/* <Button
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          color="gray"
          size="lg"
          className="mt-6"
          fullWidth
          onClick={() => setIsAddUserDialogOpen(!isAddUserDialogOpen)}
        >
          Add Staff
        </Button> */}
      </div>
      <AddUserDialog
        isOpen={isAddUserDialogOpen}
        onClose={() => setIsAddUserDialogOpen(!isAddUserDialogOpen)}
      />
    </section>
  );
}

export { DashboardPage };
