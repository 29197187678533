import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Card } from "@material-tailwind/react";
import EventModal from "./EventModal";
import "./style.css";
import axios from "axios";
import { urlServer } from "../../../Configuration/heroku";

interface Event {
  _id?: string;
  title: string;
  start: string;
  end: string | null;
  eventType: number;
}

const eventColors: { [key: number]: string } = {
  1: "bg-purple-300",
  2: "bg-pink-300",
  3: "bg-indigo-300",
  4: "bg-blue-300",
};

export default function Calendar() {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [events, setEvents] = useState<Event[]>([]);

  const handleDateClick = (info: any) => {
    setSelectedDate(info.dateStr);
    setSelectedEvent(null);
    setOpen(true);
  };

  const handleEventClick = (info: any) => {
    const event = events.find((event) => event.title === info.event.title);
    if (event) {
      setSelectedEvent(event);
      setSelectedDate(event.start);
      setOpen(true);
    }
  };

  const handleEventDrop = async (info: any) => {
    const updatedEvent = {
      _id: info.event.extendedProps._id,
      title: info.event.title,
      start: info.event.start.toISOString(),
      end: info.event.end ? info.event.end.toISOString() : null,
      eventType: info.event.extendedProps.eventType,
    };

    if (!updatedEvent._id || !updatedEvent.title) {
      console.error("Missing required fields for event update:", updatedEvent);
      info.revert();
      return;
    }
    try {
      await axios.put(
        `${urlServer}/api/${user.facilities[0].facilityId}/event/${updatedEvent._id}`,
        {
          title: updatedEvent.title,
          startDate: updatedEvent.start,
          endDate: updatedEvent.end,
          eventType: updatedEvent.eventType,
        }
      );
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event._id === updatedEvent._id
            ? { ...event, start: updatedEvent.start, end: updatedEvent.end }
            : event
        )
      );
    } catch (error) {
      console.error("Error updating event:", error);
      info.revert();
    }
  };

  const handleSaveEvent = (
    title: string,
    start: string | null,
    end: string | null,
    eventType: number
  ) => {
    if (start) {
      const updatedEvents = selectedEvent
        ? events.map((event) =>
            event.title === selectedEvent.title
              ? { ...event, title, start, end, eventType }
              : event
          )
        : [...events, { title, start, end, eventType }];
      setEvents(updatedEvents);
      setOpen(false);
    }
  };

  const handleDeleteEvent = async () => {
    if (!selectedEvent || !user) return;

    try {
      await axios.delete(
        `${urlServer}/api/${user.facilities[0].facilityId}/event/${selectedEvent._id}`
      );
      setEvents(events.filter((event) => event._id !== selectedEvent._id));
      setOpen(false);
    } catch (error) {
      console.error("Error deleting event:", error);
      alert("Failed to delete event. Please try again.");
    }
  };

  const getEvents = async () => {
    try {
      const response = await axios.get(
        `${urlServer}/api/events/${user.facilities[0].facilityId}`
      );
      const formattedEvents = response.data.map((event: any) => ({
        _id: event._id,
        title: event.title,
        start: event.startDate,
        end: event.endDate || null,
        eventType: event.eventType || 1,
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    getEvents();
  }, [user.facilities[0].facilityId]);

  return (
    <div>
      <Card
        placeholder=""
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        className="w-full h-full p-10"
      >
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          events={events.map((event) => ({
            ...event,
            className: eventColors[event.eventType],
          }))}
          editable={true}
          droppable={true}
          eventDrop={handleEventDrop}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          height="825px"
          contentHeight="100%"
          className="mt-2"
        />
        <EventModal
          open={open}
          onClose={() => {
            setOpen(false);
            getEvents();
          }}
          date={selectedDate}
          event={selectedEvent}
          onSave={handleSaveEvent}
          onDelete={handleDeleteEvent}
        />
      </Card>
    </div>
  );
}
