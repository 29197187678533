import {
  Typography,
  Card,
  CardBody,
  CardFooter,
  IconButton,
} from "@material-tailwind/react";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { urlServer } from "../../../Configuration/heroku";

type Room = {
  _id: string;
  name: string;
  units: Array<{ id: string }>; // Adjust if needed
  users: Array<{ id: string }>; // Define users as an array with objects
  capacity: number;
  roomNumber: string;
};
const TABLE_HEAD = ["Room", "Clients", "Staff", "Room Number"];

function RoomsTable() {
  const navigate = useNavigate();
  const [rooms, setRooms] = useState<Room[]>([]);
  const [filteredData, setFilteredData] = useState<Room[]>([]);
  const [tableRows, setTableRows] = useState([]);

  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const facilityId = user.facilities[0].facilityId;

  const getRoomsByFacility = async (page: number, rows: number) => {
    try {
      const response = await axios.get(
        `${urlServer}/api/rooms/${user.facilities[0].facilityId}`,
        {
          params: { page, rowsPerPage: rows },
        }
      );

      const data = response.data;
      setRooms(data.room);
      setFilteredData(data.room);
      setTotalPages(data.totalPages);
      if (response.status === 200) {
        setTotalPages(data.totalPages);
      } else {
        console.error("Failed to fetch rooms");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);

  const [openPopover, setOpenPopover] = useState<string | null>(null);
  const triggers = (id: string) => ({
    onClick: () => setOpenPopover(id),
    onMouseEnter: () => {
      if (openPopover !== null) {
        setOpenPopover(id);
      }
    },
    onMouseLeave: () => setOpenPopover(null),
  });
  const next = () => {
    if (currentPage === 10) return;
    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    getRoomsByFacility(currentPage, 10);
  }, [currentPage]);

  return (
    <section>
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="h-full w-full"
      >
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="!p-0"
        >
          <table className="w-full min-w-max table-auto text-left ">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th key={head} className="border-b border-gray-300 !p-6">
                    <Typography
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      variant="small"
                      color="blue-gray"
                      className="text-center gap-2 leading-none opacity-70 !font-bold text-gray-900"
                    >
                      {head}{" "}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.map(
                ({ _id, name, units, users, capacity, roomNumber }, index) => {
                  const isLast = index === tableRows.length - 1;
                  const classes = isLast
                    ? "px-3 py-1 text-center"
                    : "p-3 border-b border-blue-gray-50 text-center";
                  return (
                    <tr
                      key={_id}
                      className="hover:bg-gray-100 cursor-pointer"
                      onClick={() =>
                        navigate(`/facility/${facilityId}/room/${_id}`)
                      }
                    >
                      <td className={classes}>
                        <Typography
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          className="font-normal text-center"
                        >
                          {name}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          className="font-normal text-center"
                        >
                          {capacity
                            ? `${users.length} / ${capacity}`
                            : users.length}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          className="font-normal text-center"
                        >
                          {users.length}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          className="font-normal text-center"
                        >
                          {roomNumber}
                        </Typography>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </CardBody>
        <CardFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex justify-between items-center"
        >
          <div className="flex gap-4 items-center ml-auto">
            <IconButton
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="outlined"
              onClick={prev}
              disabled={currentPage === 1}
              className="border-gray-300"
            >
              <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
            </IconButton>
            <Typography
              variant="small"
              className="font-bold text-gray-600"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              Page <strong className="text-gray-900">{currentPage}</strong> of{" "}
              <strong className="text-gray-900">{totalPages}</strong>
            </Typography>
            <IconButton
              variant="outlined"
              onClick={next}
              disabled={currentPage === totalPages}
              className="border-gray-300"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
            </IconButton>
          </div>
        </CardFooter>
      </Card>
    </section>
  );
}

export default RoomsTable;
