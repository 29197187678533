import { Card, Typography, Avatar } from "@material-tailwind/react";
import React from "react";

const dummyData = [
  {
    day: "Mon",
    date: 21,
    name: "Andrea Webb",
    age: "(60 years old)",
    imgSrc:
      "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-3.jpg",
  },
  {
    day: "Tue",
    date: 22,
    name: "John Doe",
    age: "(72 years old)",
    imgSrc:
      "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
  },
  {
    day: "Wed",
    date: 23,
    name: "Jane Smith",
    age: "(65 years old)",
    imgSrc:
      "https://demos.creative-tim.com/test/corporate-ui-dashboard/assets/img/team-2.jpg",
  },
];

export function UpcomingEvents() {
  return (
    <>
      {dummyData.map((event, index) => (
        <Card
          key={index}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="w-full max-w-full my-2 p-4 border rounded-lg"
        >
          <div className="flex items-center ">
            <div className="flex flex-col items-center mr-4">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="small"
                className="text-sm"
              >
                {event.day}
              </Typography>
              <Typography
                variant="small"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className="text-sm"
              >
                {event.date}
              </Typography>
            </div>
            <Avatar
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              src={event.imgSrc}
              alt={event.name}
              size="sm"
              className="mr-4"
            />
            <div className="flex">
              <Typography
                variant="small"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className="text-md mr-1 font-semibold"
              >
                {event.name}
              </Typography>
              <Typography
                variant="small"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className="text-sm font-semibold"
              >
                {event.age}
              </Typography>
            </div>
          </div>
        </Card>
      ))}
    </>
  );
}

export default UpcomingEvents;
