import React from "react";
import {
  Typography,
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Button,
} from "@material-tailwind/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { UserIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { IUnit } from "../Data/unit";
import AssignUnits from "../Dialogs/AssignUnits";

const TABLE_HEAD = ["Name", "Date Of Birth", "Caregiver(s)", "Address"];

interface UnitsTableProps {
  units: IUnit[];
  user: any;
  refresh: () => void;
}
export const UnitsTable: React.FC<UnitsTableProps> = ({
  units,
  user,
  refresh,
}) => {
  let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);
  const next = () => {
    if (currentPage === 10) return;
    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };
  return (
    <section>
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="h-full w-full"
      >
        <div className="flex justify-between items-center mx-4">
          {" "}
          <Typography
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            variant="h6"
            className="m-3 flex items-center gap-1"
          >
            <UserIcon className="!w-5 !h-5" /> Clients
          </Typography>
          <Button
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="mt-2"
            onClick={() => setIsSelectDialogOpen((cur) => !cur)}
          >
            {" "}
            Add Client
          </Button>
        </div>
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="!p-0"
        >
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-gray-300 !p-6 text-center"
                  >
                    <Typography
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      color="blue-gray"
                      variant="small"
                      className="!font-bold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {units && units.length > 0 ? (
                units.map((unit, index) => {
                  const {
                    _id,
                    firstName,
                    lastName,
                    dateOfBirth,
                    caregivers,
                    address,
                    city,
                    state,
                    country,
                  } = unit;
                  const classes = "!p-4 border-b border-gray-300 text-center";
                  return (
                    // fix the navigating to the unit page
                    <tr
                      key={_id}
                      onClick={() => {
                        navigate(
                          `/facility/${user.facilities[0].facilityId}/unit/${_id}`,
                          { state: { units } }
                        );
                      }}
                      className="cursor-pointer hover:bg-gray-100"
                    >
                      <td className={classes}>
                        <Typography
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          variant="small"
                          color="blue-gray"
                          className="!font-normal"
                        >
                          {firstName} {lastName}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          variant="small"
                          color="blue-gray"
                          className="!font-normal"
                        >
                          {format(new Date(dateOfBirth), "MM/dd/yyyy")}
                        </Typography>
                      </td>
                      <td className={classes}>
                        {caregivers &&
                          caregivers
                            .slice(0, 2)
                            .map((caregiver: any, index: number) => (
                              <Typography
                                key={index}
                                placeholder={undefined}
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                                variant="small"
                                color="blue-gray"
                                className="!font-normal"
                              >
                                {caregiver.firstName} {caregiver.lastName}
                              </Typography>
                            ))}
                      </td>
                      <td className={classes}>
                        <Typography
                          placeholder={undefined}
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                          variant="small"
                          color="blue-gray"
                          className="!font-normal"
                        >
                          {address}, {city}, {state}, {country}
                        </Typography>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={TABLE_HEAD.length} className="p-4 text-center">
                    <Typography
                      placeholder={undefined}
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      variant="small"
                      color="blue-gray"
                      className="!font-normal"
                    >
                      No rooms found.
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
        <CardFooter
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="flex justify-between items-center"
        >
          <div className="flex gap-4 items-center ml-auto">
            <IconButton
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              variant="outlined"
              onClick={prev}
              disabled={currentPage === 1}
              className="border-gray-300"
            >
              <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
            </IconButton>
            <Typography
              variant="small"
              className="font-bold text-gray-600"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              Page <strong className="text-gray-900">{currentPage}</strong> of{" "}
              <strong className="text-gray-900">{totalPages}</strong>
            </Typography>
            <IconButton
              variant="outlined"
              onClick={next}
              disabled={currentPage === totalPages}
              className="border-gray-300"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
            </IconButton>
          </div>
        </CardFooter>
      </Card>
      <AssignUnits
        open={isSelectDialogOpen}
        onClose={() => {
          setIsSelectDialogOpen(false);
          refresh();
        }}
        units={units}
      />
    </section>
  );
};
