import {
  Breadcrumbs,
  Tabs,
  Tab,
  TabsHeader,
  TabsBody,
  TabPanel,
  Typography,
  Card,
  CardBody,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  IconButton,
  Chip,
  Button,
} from "@material-tailwind/react";
import {
  EllipsisHorizontalIcon,
  TableCellsIcon,
} from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";
import { Square3Stack3DIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { UnitProfile } from "../../../Components/UnitProfile";
import { AnnouncementFeed } from "../../../Components/AnnouncementFeed";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { UnitAttendanceLog } from "../../../Components/UnitAttendanceLog";
import { urlServer } from "../../../Configuration/heroku";

export const UnitPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<string>("profile");
  const [announcements, setAnnouncements] = useState<any[]>([]);
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const { units } = location.state || {};
  const [currentUnit, setCurrentUnit] = useState(
    units?.find((unit: any) => unit._id === id)
  );
  const { facilityId } = useParams<{ facilityId: string }>();

  const getAnnouncements = async () => {
    if (
      !currentUnit ||
      !currentUnit.facilities ||
      currentUnit.rooms.length === 0
    ) {
      console.error("No unit data or rooms found");
      return;
    }

    const roomIds = currentUnit.rooms
      .map((room: any) => room._id)
      .filter(
        (roomId: any, index: any, self: any) => self.indexOf(roomId) === index
      );

    try {
      const announcementRequests = roomIds.map((roomId: any) =>
        axios.get(`${urlServer}/api/${facilityId}/announcement/${roomId}`)
      );
      const responses = await Promise.all(announcementRequests);
      const allAnnouncements = responses.flatMap((response) => response.data);
      setAnnouncements(allAnnouncements);
    } catch (error: any) {
      console.error(
        "Error fetching announcements:",
        error.response?.data || error.description
      );
    }
  };
  const handleDeleteUnit = async () => {
    try {
      await axios.delete(`${urlServer}/api/unit/${id}`);
      navigate(`/facility/${facilityId}/units`);
    } catch (error: any) {
      console.error(
        "Error deleting unit:",
        error.response?.data || error.description
      );
    }
  };

  const updateUnitAttendance = async () => {
    try {
      const attendanceStatus =
        !currentUnit.attendanceStatus ||
        currentUnit.attendanceStatus === "checkin"
          ? "checkout"
          : "checkin";
      const response = await axios.put(`${urlServer}/api/unit/${id}`, {
        attendanceStatus: attendanceStatus,
      });
      setCurrentUnit(response.data);

      await axios.post(`${urlServer}/api/attendance-log/${facilityId}/create`, {
        unitId: currentUnit._id,
        type: attendanceStatus,
        facilityId: facilityId,
        userId: user._id,
      });
    } catch (error: any) {
      console.error(
        "Error updating unit:",
        error.response?.data || error.description
      );
    }
  };

  const fetchUnit = async () => {
    try {
      const response = await axios.get(`${urlServer}/api/unit/${id}`, {
        params: {
          caregivers: true,
          rooms: true,
        },
      });
      setCurrentUnit(response.data);
    } catch (error) {
      console.error("Error fetching unit data:", error);
    }
  };

  useEffect(() => {
    fetchUnit();
  }, [id]);

  useEffect(() => {
    getAnnouncements();
  }, []);
  if (!currentUnit) {
    return <div>No unit found.</div>;
  }

  const data = [
    {
      label: "Feed",
      value: "feed",
      icon: Square3Stack3DIcon,
      desc: "It really matters and then like it really doesn't matter.",
      Component: AnnouncementFeed,
      props: { announcements },
    },
    {
      label: "Profile",
      value: "profile",
      icon: UserCircleIcon,
      desc: "Because it's about motivating the doers. Because I'm here to follow my dreams and inspire other people to follow their dreams, too.",
      Component: UnitProfile,
    },
    {
      label: "Attendance Log",
      value: "attendace",
      icon: TableCellsIcon,
      desc: "Because it's about motivating the doers. Because I'm here to follow my dreams and inspire other people to follow their dreams, too.",
      Component: UnitAttendanceLog,
    },
  ];

  return (
    <div className="flex flex-col gap-4 p-4">
      <Breadcrumbs
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <a href="/" className="opacity-60">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
        </a>
        <a href={`/facility/${facilityId}/units`} className="opacity-60">
          Clients
        </a>
        <a>
          {currentUnit.firstName} {currentUnit.lastName}
        </a>
      </Breadcrumbs>
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="flex items-center justify-between w-full">
            <div className="ml-4">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="h6"
              >
                {currentUnit.firstName} {currentUnit.lastName}
              </Typography>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="blue-gray"
              >
                {format(new Date(currentUnit.dateOfBirth), "MM/dd/yyyy")}
              </Typography>
            </div>
            <div className="flex float-end">
              <Button
                onClick={updateUnitAttendance}
                className="p-0"
                variant="text"
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <Chip
                  className="w-20 h-full text-center"
                  color={
                    !currentUnit.attendanceStatus ||
                    currentUnit.attendanceStatus === "checkin"
                      ? "green"
                      : "red"
                  }
                  value={currentUnit.attendanceStatus || "checkin"}
                />
              </Button>
              <Menu placement="left">
                <MenuHandler>
                  <IconButton
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    variant="text"
                  >
                    <EllipsisHorizontalIcon className="h-8 w-8" />
                  </IconButton>
                </MenuHandler>
                <MenuList
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <MenuItem
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    onClick={handleDeleteUnit}
                  >
                    Delete Client
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </CardBody>
      </Card>
      <Tabs
        value={selectedTab}
        onChange={(value: string) => {
          setSelectedTab(value as string);
        }}
      >
        <TabsHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {data.map(({ label, value, icon }) => (
            <Tab
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              key={value}
              value={value}
            >
              <div className="flex items-center gap-2">
                {React.createElement(icon, { className: "w-5 h-5" })}
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          {data.map(({ value, Component, props }) => (
            <TabPanel key={value} value={value} className="px-0">
              {Component ? (
                <Component
                  {...props}
                  getAnnouncements={getAnnouncements}
                  announcements={announcements}
                />
              ) : null}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
};
