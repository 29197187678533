import {
  Breadcrumbs,
  Tabs,
  Tab,
  TabsHeader,
  TabsBody,
  TabPanel,
  Typography,
  Card,
  CardBody,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  IconButton,
  Chip,
} from "@material-tailwind/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";
import { Square3Stack3DIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import UserInformation from "./UserInformation";
import UserToRoom from "../../../Dialogs/UserToRoom";
import { UserRooms } from "./UserRooms";
import { urlServer } from "../../../Configuration/heroku";

export const UserPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<string>("profile");
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);

  const { users, user1 } = location.state || {};

  // IMPROVE
  let currentuser: any;
  if (Array.isArray(users)) {
    currentuser = users.find((user: any) => user._id === id);
  } else if (user1) {
    currentuser = user1;
  } else if (typeof users === "object") {
    currentuser = users;
  } else {
    currentuser = null;
  }

  if (!currentuser) {
    return (
      <div>
        <Typography
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          variant="h6"
          color="red"
        >
          User not found. Please navigate to a valid user.
        </Typography>
      </div>
    );
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const facilityId = user?.facilities?.[0]?.facilityId || "";

  const handleDeleteUser = async () => {
    try {
      await axios.delete(`${urlServer}/api/user/${id}`);
      navigate(`/facility/${facilityId}/users`);
    } catch (error: any) {
      console.error(
        "Error deleting user:",
        error.response?.data || error.description
      );
    }
  };

  const data = [
    {
      label: "Profile",
      value: "profile",
      icon: UserCircleIcon,
      Component: UserInformation,
      props: { currentuser },
    },
    {
      label: "Rooms",
      value: "room",
      icon: UserCircleIcon,
      Component: UserRooms,
      props: { currentuser },
    },
  ];
  return (
    <div className="flex flex-col gap-4 p-4">
      <Breadcrumbs
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <a href="/" className="opacity-60">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
        </a>
        <a href={`/facility/${facilityId}/users`} className="opacity-60">
          Users
        </a>
        <a>
          {currentuser.firstName} {currentuser.lastName}
        </a>
      </Breadcrumbs>
      <Card
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <CardBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="flex items-center justify-between w-full">
            <div className="ml-4">
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                variant="h6"
                className="flex items-center gap-2"
              >
                {currentuser.firstName} {currentuser.lastName}{" "}
                <Chip
                  className="w-20 text-center"
                  color={currentuser.status === "active" ? "green" : "red"}
                  value={currentuser.status}
                />
              </Typography>
              <Typography
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                color="blue-gray"
              >
                {currentuser.email}
              </Typography>
            </div>
            <div className="float-end">
              <Menu placement="left">
                <MenuHandler>
                  <IconButton
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    variant="text"
                  >
                    <EllipsisHorizontalIcon className="h-8 w-8" />
                  </IconButton>
                </MenuHandler>
                <MenuList
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <MenuItem
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    onClick={handleDeleteUser}
                  >
                    Delete User
                  </MenuItem>
                  <MenuItem
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    onClick={() => setIsSelectDialogOpen((cur) => !cur)}
                  >
                    Assign Room
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </CardBody>
      </Card>
      <Tabs
        value={selectedTab}
        onChange={(value: string) => {
          setSelectedTab(value as string);
        }}
      >
        <TabsHeader
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          {data.map(({ label, value, icon }) => (
            <Tab
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              key={value}
              value={value}
            >
              <div className="flex items-center gap-2">
                {React.createElement(icon, { className: "w-5 h-5" })}
                {label}
              </div>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          {data.map(({ value, Component, props }) => (
            <TabPanel key={value} value={value} className="px-0">
              {Component ? (
                <Component {...props} currentuser={currentuser} />
              ) : null}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
      <UserToRoom
        open={isSelectDialogOpen}
        onClose={() => {
          setIsSelectDialogOpen(false);
        }}
        user={currentuser}
        rooms={[]}
      />
    </div>
  );
};
